import { relayHandler } from "./relayHandler.js";

export const changeEvents = {
    mixins: [relayHandler],
    methods: {

        /**
         * When product is changed during SignUp
         * @param {object} changedSignUpProduct
         */
        onSignUpProductChange: function (changedSignUpProduct) {

            window.console.log('onSignUpProductChange registered');

            //  Clone object to unbind default data bindings
            this.product = Object.assign({}, changedSignUpProduct);
            this.staticWorkflowProduct = Object.assign({}, changedSignUpProduct);

            //  Clear agreement data from session request
            this.sessionRequest.agreementData = {};
            this.sessionRequest.originalAgreementData = {};

            //  Apply agreement data to session request and product, in the case configuration was updated since Workflow was last updated
            if (
                this.sessionRequest.workflow.serviceType === 'SignUp' &&
                this.product.paymentType === 'Recurring' &&
                this.product.agreementGuid
            ) {
                this.applySharedAgreementToProduct(this.product.agreementGuid);
            }

            //  Prefill by query
            this.handleDeeplinks();

            //  Emit
            this.$emit("onSignUpProductChanged");
        },

        /**
         * When product is changed during Upgrade
         * @param {object} upgradeProduct
         */
        onUpgradeProductChange: function (upgradeProduct) {

            window.console.log('onUpgradeProductChange registered');

            //  Clone object to unbind default data bindings
            this.product = Object.assign({}, upgradeProduct);

            //  Set helping defaults
            if (upgradeProduct.upgradeType === 'AgreementUpdate') {

                //  Maintain scheduleType
                if (!upgradeProduct.scheduleType) {
                    this.product.scheduleType = this.sessionRequest.agreementData.scheduleType;
                }

                //  Get first day of next month
                let firstDate = new Date();
                firstDate.setMonth(firstDate.getMonth() + 1, 1);

                let dd = String(firstDate.getDate());
                let mm = String(firstDate.getMonth() + 1);

                if (!upgradeProduct.scheduleCalendarUnit) {
                    this.product.scheduleCalendarUnit = 'Month';
                    this.staticWorkflowProduct.scheduleCalendarUnit = 'Month';
                }
                if (!upgradeProduct.scheduleFixedDay) {
                    this.product.scheduleFixedDay = dd;
                    this.product.scheduleFixedDay = dd;
                }
                if (!upgradeProduct.scheduleBaseTier) {
                    this.product.scheduleBaseTier = mm;
                    this.product.scheduleBaseTier = mm;
                }

                //  TODO : scheduleSelectedSet
                if (!upgradeProduct.hasScheduleSelectedSet) {
                    this.product.scheduleSelectedSet = null;
                }

                this.onScheduleTypeChange(this.product);
            }

            //  Prefill by query
            this.handleDeeplinks();
        },

        /**
         * When agreementType is changed in workflow configuration
         * @param {object} workflowProduct 
         */
        onAgreementTypeChange: function (workflowProduct) {

            window.console.log('onAgreementTypeChange registered');

            if (workflowProduct.paymentType === 'Recurring' && workflowProduct.agreementType !== null && workflowProduct.agreementType !== 'Personal') {
                let agreementGuid = workflowProduct.agreementType;

                let agreementHandler = this.getAgreement(agreementGuid);
                if (typeof agreementHandler.then !== "undefined") {
                    agreementHandler.then(response => {
                        if (!response.agreementErrored) {
                            this.updateWorkflowProductByAgreement(workflowProduct, response.agreement);
                        }

                    });
                } else if (agreementHandler.agreement) {
                    this.updateWorkflowProductByAgreement(workflowProduct, agreementHandler.agreement);
                }
            }
        },

        /**
         * Update workflow product agreement details
         * @param {object} workflowProduct 
         * @param {object} agreement 
         */
        updateWorkflowProductByAgreement: function (workflowProduct, agreement) {

            workflowProduct.agreementGuid = agreement.agreementGuid;
            workflowProduct.agreementName = agreement.name;
            workflowProduct.agreementDescription = (agreement.description ? agreement.description : null);
            workflowProduct.amountTotal = (agreement.amountTotal ? agreement.amountTotal : 0);
            workflowProduct.vatPercentage = (agreement.vatPercentage ? agreement.vatPercentage : 0);
            workflowProduct.currencyCode = (agreement.currencyCode ? agreement.currencyCode : 'DKK');
            workflowProduct.communicationCollectionGuid = (agreement.communicationCollectionGuid ? agreement.communicationCollectionGuid : null);
            workflowProduct.purposeAccountingCode = (agreement.purposeAccountingCode ? agreement.purposeAccountingCode : null);
            workflowProduct.paymentRequired = (agreement.paymentRequired ? agreement.paymentRequired : false);
            workflowProduct.taxDeductable = (agreement.taxDeductable ? agreement.taxDeductable : false);
            workflowProduct.scheduleBaseTier = agreement.scheduleBaseTier;
            workflowProduct.scheduleCalendarUnit = agreement.scheduleCalendarUnit;
            workflowProduct.scheduleEveryOther = agreement.scheduleEveryOther;
            workflowProduct.scheduleFixedDay = agreement.scheduleFixedDay;
            workflowProduct.scheduleType = agreement.scheduleType;
        },

        /**
         * When scheduleStartType is changed (re)set custom start date
         */
        onScheduleStartTypeChanged: function () {

            window.console.log("onScheduleStartTypeChanged triggered: " + this.product.scheduleStartType);

            this.product.scheduleStartCustomDate = {
                startDate: null,
                endDate: null
            }
        },

        /**
         * When scheduleType is changed, both in workflow configuration and onboarding
         * @param {object} workflowProduct
         */
        onScheduleTypeChange: function (workflowProduct) {

            window.console.log('onScheduleTypeChange registered: ' + workflowProduct.scheduleType);

            //  Weekly
            if (workflowProduct.scheduleType === "Weekly") {

                workflowProduct.scheduleCalendarUnit = "Week";
                workflowProduct.scheduleEveryOther = 1;
                this.$delete(workflowProduct, "scheduleFixedDay");
                this.$delete(workflowProduct, "scheduleBaseTier");
                this.$delete(workflowProduct, "scheduleSelectedSet");
            }

            //  scheduleSelectedSet
            else if (workflowProduct.hasScheduleSelectedSet) {

                workflowProduct.scheduleType = "Monthly"
                workflowProduct.scheduleCalendarUnit = "Month";
                workflowProduct.scheduleEveryOther = 1;
                workflowProduct.scheduleFixedDay = 1;
                workflowProduct.scheduleBaseTier = 1;
                workflowProduct.scheduleSelectedSet = this.convertScheduleSelectedSetDefinitionToString(workflowProduct);
            }

            //  Monthly
            else if (workflowProduct.scheduleType === "Monthly") {

                workflowProduct.scheduleCalendarUnit = "Month";
                workflowProduct.scheduleEveryOther = 1;
                this.$delete(workflowProduct, "scheduleFixedDay");
                this.$delete(workflowProduct, "scheduleBaseTier");
                this.$delete(workflowProduct, "scheduleSelectedSet");
            } else if (workflowProduct.scheduleType === "MonthlyFirst") {

                workflowProduct.scheduleCalendarUnit = "Month";
                workflowProduct.scheduleEveryOther = 1;
                workflowProduct.scheduleFixedDay = 1;
                workflowProduct.scheduleBaseTier = 1;
                this.$delete(workflowProduct, "scheduleSelectedSet");
            }

            //  Quarterly
            else if (workflowProduct.scheduleType === "Quarterly") {

                workflowProduct.scheduleCalendarUnit = "Month";
                workflowProduct.scheduleEveryOther = 3;
                this.$delete(workflowProduct, "scheduleFixedDay");
                this.$delete(workflowProduct, "scheduleBaseTier");
                this.$delete(workflowProduct, "scheduleSelectedSet");
            } else if (workflowProduct.scheduleType === "QuarterlyFirst") {

                workflowProduct.scheduleCalendarUnit = "Month";
                workflowProduct.scheduleEveryOther = 3;
                workflowProduct.scheduleFixedDay = 1;
                workflowProduct.scheduleBaseTier = 1;
                this.$delete(workflowProduct, "scheduleSelectedSet");
            }

            //  Halfyearly
            else if (workflowProduct.scheduleType === "Halfyearly") {

                workflowProduct.scheduleCalendarUnit = "Month";
                workflowProduct.scheduleEveryOther = 6;
                this.$delete(workflowProduct, "scheduleFixedDay");
                this.$delete(workflowProduct, "scheduleBaseTier");
                this.$delete(workflowProduct, "scheduleSelectedSet");
            } else if (workflowProduct.scheduleType === "HalfyearlyFirst") {

                workflowProduct.scheduleCalendarUnit = "Month";
                workflowProduct.scheduleEveryOther = 6;
                workflowProduct.scheduleFixedDay = 1;
                workflowProduct.scheduleBaseTier = 1;
                this.$delete(workflowProduct, "scheduleSelectedSet");
            }

            //  Yearly
            else if (workflowProduct.scheduleType === "Yearly") {

                workflowProduct.scheduleCalendarUnit = "Month";
                workflowProduct.scheduleEveryOther = 12;
                this.$delete(workflowProduct, "scheduleFixedDay");
                this.$delete(workflowProduct, "scheduleBaseTier");
                this.$delete(workflowProduct, "scheduleSelectedSet");
            } else if (workflowProduct.scheduleType === "YearlyFirst") {

                workflowProduct.scheduleCalendarUnit = "Month";
                workflowProduct.scheduleEveryOther = 12;
                workflowProduct.scheduleFixedDay = 1;
                workflowProduct.scheduleBaseTier = 1;
                this.$delete(workflowProduct, "scheduleSelectedSet");
            }

            //  Custom
            else if (workflowProduct.scheduleType === "Custom") {

                //  Do nothing

            }

            //  Manual
            else if (workflowProduct.scheduleType === "Manual") {

                this.$delete(workflowProduct, "scheduleCalendarUnit");
                this.$delete(workflowProduct, "scheduleEveryOther");
                this.$delete(workflowProduct, "scheduleFixedDay");
                this.$delete(workflowProduct, "scheduleBaseTier");
                this.$delete(workflowProduct, "scheduleSelectedSet");
            }

            //  No selected
            else {

                this.$delete(workflowProduct, "scheduleCalendarUnit");
                this.$delete(workflowProduct, "scheduleEveryOther");
                this.$delete(workflowProduct, "scheduleFixedDay");
                this.$delete(workflowProduct, "scheduleBaseTier");
                this.$delete(workflowProduct, "scheduleSelectedSet");
            }

            this.$forceUpdate();
        },

        /**
         * When scheduleEveryOther is changed, both in workflow configuration and onboarding
         * @param {object} workflowProduct 
         */
        onScheduleEveryOtherChange: function (workflowProduct) {

            window.console.log('onScheduleEveryOtherChange registered: ' + parseInt(workflowProduct.scheduleEveryOther));

            const scheduleEveryOther = parseInt(workflowProduct.scheduleEveryOther);

            if (workflowProduct.scheduleCalendarUnit === "Month") {

                if (scheduleEveryOther === 1) {
                    workflowProduct.scheduleType = "Monthly";
                } else if (scheduleEveryOther === 3) {
                    workflowProduct.scheduleType = "Quarterly";
                } else if (scheduleEveryOther === 6) {
                    workflowProduct.scheduleType = "Halfyearly";
                } else if (scheduleEveryOther === 12) {
                    workflowProduct.scheduleType = "Yearly";
                } else {
                    workflowProduct.scheduleType = "Custom";
                }

            } else if (workflowProduct.scheduleCalendarUnit === "Week") {

                if (scheduleEveryOther === 1) {
                    workflowProduct.scheduleType = "Weekly";
                } else {
                    workflowProduct.scheduleType = "Custom";
                }
            }
        },

        /**
         * When scheduleCalendarUnit is changed, both in workflow configuration and onboarding
         * @param {object} workflowProduct 
         */
        onScheduleCalendarUnitChange: function (workflowProduct) {

            window.console.log('onScheduleCalendarUnitChange registered: ' + parseInt(workflowProduct.scheduleEveryOther));

            const scheduleEveryOther = parseInt(workflowProduct.scheduleEveryOther);

            if (workflowProduct.scheduleCalendarUnit === "Month") {

                if (scheduleEveryOther === 1) {
                    workflowProduct.scheduleType = "Monthly";
                } else if (scheduleEveryOther === 3) {
                    workflowProduct.scheduleType = "Quarterly";
                } else if (scheduleEveryOther === 6) {
                    workflowProduct.scheduleType = "Halfyearly";
                } else if (scheduleEveryOther === 12) {
                    workflowProduct.scheduleType = "Yearly";
                } else {
                    workflowProduct.scheduleType = "Custom";
                }

            } else if (workflowProduct.scheduleCalendarUnit === "Week") {

                if (scheduleEveryOther === 1) {
                    workflowProduct.scheduleType = "Weekly";
                } else {
                    workflowProduct.scheduleType = "Custom";
                }
            }
        },

        /**
         * Handle deep link by query
         */
        handleDeeplinks: function () {

            window.console.log('handleDeeplinks registered');

            if (this.staticWorkflowProduct.paymentMethodType) {
                //  Respect workflow configured value
            } else if (this.$route.query.pre_paymentMethodType) {

                //  Detect typos
                const paymentMethodType = this.$route.query.pre_paymentMethodType.trim();
                if (
                    paymentMethodType === 'Test' ||
                    paymentMethodType === 'Card' ||
                    paymentMethodType === 'MobilePay' ||
                    paymentMethodType === 'Betalingsservice' ||
                    paymentMethodType === 'DirectDebit' ||
                    paymentMethodType === 'Giro' ||
                    paymentMethodType === 'Invoice' ||
                    paymentMethodType === 'EAN'
                ) {
                    this.product.paymentMethodType = paymentMethodType;
                } else {
                    this.$root.errorResponse("Attention! Your pre_paymentMethodType-variable seems invalid. Please review!");
                }
            }

            if (this.staticWorkflowProduct.scheduleType) {
                //  Respect workflow configured value
            } else if (this.$route.query.pre_scheduleType) {

                //  Detect typos
                const scheduleType = this.$route.query.pre_scheduleType.trim();
                if (
                    scheduleType === 'Manual' ||
                    scheduleType === 'Weekly' ||
                    scheduleType === 'Monthly' ||
                    scheduleType === 'Quarterly' ||
                    scheduleType === 'Halfyearly' ||
                    scheduleType === 'Yearly' ||
                    scheduleType === 'Custom'
                ) {
                    this.product.scheduleType = scheduleType;
                    this.onScheduleTypeChange(this.product);
                } else {
                    this.$root.errorResponse("Attention! Your pre_scheduleType-variable seems invalid. Please review!");
                }
            }

            if (this.staticWorkflowProduct.scheduleEveryOther) {
                //  Respect workflow configured value
            } else if (this.$route.query.pre_scheduleEveryOther) {

                //  Detect typos
                const scheduleEveryOther = parseInt(this.$route.query.pre_scheduleEveryOther.trim());
                if (!isNaN(scheduleEveryOther)) {
                    this.product.scheduleEveryOther = scheduleEveryOther;
                    this.onScheduleEveryOtherChange(this.product);
                } else {
                    this.$root.errorResponse("Attention! Your pre_scheduleEveryOther-variable is not a number. Please review!");
                }
            }

            if (this.staticWorkflowProduct.scheduleCalendarUnit) {
                //  Respect workflow configured value
            } else if (this.$route.query.pre_scheduleCalendarUnit) {

                //  Detect typos
                const scheduleCalendarUnit = this.$route.query.pre_scheduleCalendarUnit.trim();
                if (
                    scheduleCalendarUnit === 'Week' ||
                    scheduleCalendarUnit === 'Month'
                ) {
                    this.product.scheduleCalendarUnit = scheduleCalendarUnit;
                    this.onScheduleCalendarUnitChange(this.product);
                } else {
                    this.$root.errorResponse("Attention! Your pre_scheduleCalendarUnit-variable seems invalid. Please review!");
                }
            }

            if (this.staticWorkflowProduct.scheduleFixedDay) {
                //  Respect workflow configured value
            } else if (this.$route.query.pre_scheduleFixedDay) {

                //  Detect typos
                const scheduleFixedDay = parseInt(this.$route.query.pre_scheduleFixedDay.trim());
                if (!isNaN(scheduleFixedDay)) {
                    this.product.scheduleFixedDay = scheduleFixedDay;
                } else {
                    this.$root.errorResponse("Attention! Your pre_scheduleFixedDay-variable is not a number. Please review!");
                }
            }

            if (this.staticWorkflowProduct.scheduleBaseTier) {
                //  Respect workflow configured value
            } else if (this.$route.query.pre_scheduleBaseTier) {

                //  Detect typos
                const scheduleBaseTier = parseInt(this.$route.query.pre_scheduleBaseTier.trim());
                if (!isNaN(scheduleBaseTier)) {
                    this.product.scheduleBaseTier = scheduleBaseTier;
                } else {
                    this.$root.errorResponse("Attention! Your pre_scheduleBaseTier-variable is not a number. Please review!");
                }
            }

            if (this.staticWorkflowProduct.scheduleStartType) {
                //  Respect workflow configured value
            } else if (this.$route.query.pre_scheduleStartType) {

                //  Detect typos
                const scheduleStartType = this.$route.query.pre_scheduleStartType.trim();
                if (
                    scheduleStartType === 'now' ||
                    scheduleStartType === 'first' ||
                    scheduleStartType === 'custom'
                ) {
                    this.product.scheduleStartType = scheduleStartType;
                    this.product.scheduleStartCustomDate = {
                        startDate: null,
                        endDate: null
                    }
                } else {
                    this.$root.errorResponse("Attention! Your pre_scheduleStartType-variable seems invalid. Please review!");
                }
            }

            //  Infer "custom" scheduleStartType, if Workflow allows and a scheduleStartCustomDate is defined by query
            //  Fixes issue if dialer defines scheduleStartType to "now" or "first", and also defines a scheduleStartCustomDate
            if ((!this.staticWorkflowProduct.scheduleStartType || this.staticWorkflowProduct.scheduleStartType === 'custom') && this.$route.query.pre_scheduleStartCustomDate) {
                this.product.scheduleStartType = 'custom';
                this.product.scheduleStartCustomDate = {
                    startDate: this.$route.query.pre_scheduleStartCustomDate.trim(),
                    endDate: null
                }
            }

            if (this.staticWorkflowProduct.expiresAfterDate && Object.keys(this.staticWorkflowProduct.expiresAfterDate).length > 0) {
                //  Respect workflow configured value
            } else if (this.$route.query.pre_expiresAfterDate) {
                this.product.expiresAfterDate = {
                    startDate: this.$route.query.pre_expiresAfterDate.trim(),
                    endDate: null
                }
            }

            if (this.staticWorkflowProduct.scheduleStartWithOneOff) {
                //  Respect workflow configured value
                if (this.$route.query.pre_scheduleStartWithOneOff === 'true') {
                    this.product.startWithOneOff = true;
                } else if (this.$route.query.pre_scheduleStartWithOneOff === 'false') {
                    this.product.startWithOneOff = false;
                }
            }

            if (this.staticWorkflowProduct.amountTotal) {
                //  Respect workflow configured value
            } else if (this.$route.query.pre_amountTotal) {
                this.product.amountTotal = this.$route.query.pre_amountTotal.trim();
            } else if (this.$route.query.pre_amount) {
                this.product.amountTotal = this.$route.query.pre_amount.trim();
            }

            if (this.staticWorkflowProduct.oneOffAmount) {
                //  Respect workflow configured value
            } else if (this.$route.query.pre_oneOffAmount) {
                this.product.oneOffAmount = this.$route.query.pre_oneOffAmount.trim();
            } else if (this.$route.query.pre_amountTotal) {
                this.product.oneOffAmount = this.$route.query.pre_amountTotal.trim();
            } else if (this.$route.query.pre_amount) {
                this.product.oneOffAmount = this.$route.query.pre_amount.trim();
            }

            //  Fallback to workflow configured value for base amount
            else if (this.staticWorkflowProduct.amountTotal) {
                this.product.oneOffAmount = this.staticWorkflowProduct.amountTotal;
            }

            if (this.staticWorkflowProduct.purposeAccountingCode) {
                //  Respect workflow configured value
            } else if (this.$route.query.pre_purposeAccountingCode) {
                this.product.purposeAccountingCode = this.$route.query.pre_purposeAccountingCode.trim();
            }

            if (this.$route.query.pre_sortCode) {
                this.product.sortCode = this.$route.query.pre_sortCode.trim();
            }

            if (this.$route.query.pre_accountNumber) {
                this.product.accountNumber = this.$route.query.pre_accountNumber.trim();
            }

            if (this.$route.query.sendSms === 'true') {
                this.product.sendSms = true;
            }

            if (this.$route.query.onlyButton === 'true') {
                this.product.onlyButton = true;
            }
        }
    }
}