<template>
  <div class="container onboarding-result">
    <template v-if="this.$route.query.state === 'Complete'">
      <div class="status-icon status-complete"></div>
    </template>
    <template v-else>
      <div class="status-icon status-failed"></div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "OnboardingResult",
});
</script>
